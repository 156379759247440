.container {
  height: 100%;
  position: relative;
  .filter {
    height: 100%;
    display: flex;
    flex-direction: column;
    .scroll {
      width: 100%;
      display: flex;
      flex: 1;
      overflow-y: auto;
      flex-direction: column;
    }
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      .icon {
        margin-right: 8px;
        font-size: 18px;
      }
      .text {
        display: flex;
        flex: 1;
        margin: -4px 0 0 0;
      }
      .arrow {
        font-size: 16px;
      }
    }
    .header.collapsed {
      .arrow {
        padding-top: 10px;
      }
    }
    .buttonGroup {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 4px;
      padding: 0 12px;
      .flexButton {
        flex: 1;
      }
    }
    .filterLabel {
      margin: 0;
      padding: 4px 12px;
    }
    .filterLabel:first-child {
      margin-top: 12px;
    }
  }
  .content {
    overflow: auto;
  }
}



.content {
  height: 100%;
}


.collapsedContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 15px 12px;
    justify-content: center;
    .icon {
      margin-bottom: 8px;
      font-size: 18px;
    }
  }
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 12px 12px 12px;
  width: calc(100% - 24px);
  .filter {
    display: inline-flex;
    width: calc(100% - 10px);
    flex-direction: row;
    padding: 8px 4px 8px 0;
    .operator {
      margin-right: 8px;
    }
    .numberInput {
      width: 100%;
    }
  }
}

.dateFilterContainer {
  display: flex;
  flex: 1;
}

.moreThanOneFilter {
  .tags {
    > span:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    > span:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
    > span:not(:last-child) {
      border-bottom-style: dashed;
      margin-bottom: 0;
    }
  }
}
