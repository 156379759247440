html, body, :global(#root), .mainLayout {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  :global(.ant-popover-inner-content) {
    padding: 0 !important;
  }
}

.mainLayout {
  display: flex;
  flex-direction: column;
  flex: 1;
  .content {
    height: 100%;
    position: relative;
  }
}

.loginContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .loginCard {
    width: 350px;
    padding: 12px;
    .loginContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        margin: 0 !important;
      }
      h5 {
        margin: 18px 0 24px 0 !important;
      }
      .loginButton {
        width: 200px;
      }
    }
  }
}
