$label-box-padding: 4px;
$indicator-font-color: #3B2722;
$node-box-border: 1px solid #BEBEBE;

.labelBox {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin-bottom: 18px;
  border: $node-box-border;
  font-size: 12px;
  border-radius: 2px;
  height: 32px;
  padding: 4px;
  .label {
    display: flex;
    flex: 1;
    padding: 2px;
  }
  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f57d1a;
    aspect-ratio : 1 / 1;
    border-radius: 2px;
    height: calc(100% - 2*$label-box-padding);
    margin: $label-box-padding;
    color: $indicator-font-color;
  }
}

.groupNode {
  .labelBox {
    background: #E5E5E5;
  }
  min-width: 180px;
  max-width: 180px;
  .inputBox:before {
    content:"";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 2px dotted #BEBEBE;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    outline: 1px solid #A4CBF3;
    border-radius: 2px;
    background: white;
    padding: 2px;
    .input.active {
      border: 2px solid #A4CBF3;
    }
    .input {
      display: flex;
      flex-direction: row;
      border: 1px solid lightgray;
      padding: 2px;
      align-items: flex-start;
      cursor: pointer;
      .label {
        display: flex;
        flex: 1;
        font-size: 10px;
        flex-wrap: wrap;
        align-self: center;
        padding-left: 2px;
      }
      .indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #87d068;
        width: 20px;
        height: 20px;
        color: $indicator-font-color;
        font-size: 12px;
        border-radius: 2px;
      }
    }
    .input:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  .indicatorTrigger {
    cursor: pointer !important;
  }

}

.indicatorMenuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: $indicator-font-color;
  border-radius: 2px;
  height: 100%;
  aspect-ratio: 1;
  min-width: 20px;
}


.indicatorDropdown {
  :global(.ant-dropdown-menu-item) {
    padding: 0 0 4px 0 !important;
  }
  :global(.ant-dropdown-menu-item):last-child {
    padding: 0 !important;
  }
  .indicatorMenuItem {
    width: 32px;
    height: 32px;
  }
}

.resultNode {
  min-width: 180px;
  max-width: 180px;
  .labelBox {
    background: #5FBAEA;
  }
}

.riskModelViewer {
  height: 100%;
  width: 100%;
  position: relative;
  .statisticsPane {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 12px;
      cursor: pointer;
      z-index: 1000000000000;
    }
    .content {
      display: flex;
      flex-direction: row;
      overflow: auto;
      .filterBar {
      }
      .statisticGrid {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        padding: 12px;
        gap: 24px;
        .statisticContainer {
          flex: 1 0 46%;
          max-width: 50%;
          height: 200px;
          width: 100px;
          .canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}

.comparisonValues {
  position: absolute;
  z-index: 100000;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .label {
    font-size: 16px;
    margin-right: 12px;
  }
  .select {
    width: 180px;
  }
}

:global(.ant-select-dropdown) {
  z-index: 1000000;
}