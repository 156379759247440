.paneContainer {
  display: flex;
  flex-direction: column;
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .paneContent {
    width: 100%;
    height: 100%;
    :global(.ant-tabs) {
      display: flex;
      flex: 1;
      height: 100%;
      /*:global(.ant-tabs-tabpane:not(.ant-tabs-tabpane-active)) {
        display: flex !important;
        visibility: hidden;
      }*/
      :global(.ant-tabs-nav) {
        margin: 0;
      }
      :global(.ant-tabs-content-holder) {
        position: relative;
      }
    }
  }
}

.tabBarContainer {
  width: 100%;
  height: 20px;
}

.topToolBar {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  padding-top: 20px;
  background: white;
  padding-left: 2px;
  padding-right: 2px;
}

.topToolBar.notVisible {
  display: none !important;
}
