.map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .buttonContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .topLeft {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 15px;
      top: 15px;
      z-index: 100;
      gap: 10px;
    }
    .topRight {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 100;
      gap: 10px;
    }
    .bottomLeft {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 15px;
      bottom: 15px;
      z-index: 100;
      gap: 10px;
    }
  }
}

.dragBox {
  border: 2px solid #1e71d4;
  background: rgba(30, 113, 212, 0.1);
}

.popoverContainer {
  gap: 8px;
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .item.default {
    cursor: pointer;
  }
  .header {
    padding: 10px 0 0 10px;
  }
}


:global(.ant-tabs-content-holder) .map {
  top: -6px !important;
}

.accidentControl {
  padding: 24px 0 6px 0;
  .radioGroup {
    display: flex;
    flex-direction: row;
    .radioButton {
      display: flex;
      flex: 1;
    }
  }
  .marginBottom8 {
    margin-bottom: 8px;
  }
  .lastFormItem {
    margin-bottom: 0;
  }
  .updateButton {
    width: 100%;
  }
}
