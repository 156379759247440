.splitPane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pageContainer {
  display:flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.statisticViewMakerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  position: relative;
  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 12px 0 12px;
    gap: 6px;
    :global(.ant-btn) {
      transition: 0ms !important;
    }
  }
  .content {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    > :global(.ant-card-body) {
      width: 100%;
      padding: 0 !important;
    }
  }
  .content {
    height: 100%;
  }
}

.editorContainer {
  display: flex;
  flex-direction: row;
  .formContainer {
    display: flex;
    flex: 1;
    .form {
      width: 100%;
    }
  }
  .preview {
    background: rgba(0, 0, 0, 0.01);
    border: 1px dashed rgba(0, 0, 0, 0.1);
  }
}

.rendererContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  .renderButtonContainer {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  }
}

.component, .fullscreen {
  position: relative;
  width: 100%;
  height: 100%;
}
