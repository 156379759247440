.editingContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 32px 0;
  gap: 32px;
  .right {
    display: flex;
    flex-direction: column;
  }
  .left {
    display: flex;
    flex-direction: column;
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    margin: 8px 12px 0 12px;
    padding: 8px;
    gap: 8px;
    .icon {
      font-size: 12px;
      color: gray;
      margin-top: 2px;
    }
  }
  .columnList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 4px 0;
  }
  .columnListContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .moveColumnsButton {

    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  .editButtonContainer {
    display: flex;
    flex: 1;
    .icon {
      color: gray;
      font-size: 18px;
      cursor: pointer;
    }
  }
}


.tableContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 450px;
    overflow-y: auto;
    .table {
      margin: 16px;
      width: calc(100% - 32px);
    }
  }
}

.downloadButton {
  border-left-width: 0;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
