.container {
  padding: 20px 20px 10px 20px;
  > :global(.ant-card) {
    > :global(.ant-card-body) {
      padding: 12px 12px 6px 12px;
      > span {
        margin-bottom: 6px;
      }
    }
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-height: 38px;
    .filters {
      display: flex;
      flex: 1;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      > span {
        margin: 0;
      }
    }
    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      .dropdownContainer {

      }
    }
  }
  .card {
    padding-bottom: 6px;
  }
}

.modalContent {
  margin-top: 20px;
  .buttonContainer {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.noFilter {
  margin-bottom: 1px;
  padding-left: 5px;
}
