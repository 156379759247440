.card {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  .table {
    width: 100%;
  }
  .dataCardRow {
    padding: 10px 12px 0 12px;
  }
  .content {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
  }
  .rawText {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin: 10px 10px 0 10px;
    min-height: 100px;
  }
  .rawTextInner {
    margin: 10px;
  }
  .actions {
    display: flex;
    flex-direction: row;
    margin: 20px 5px 10px 5px;
  }
  .action {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  .action:last-child {
    border-right: 0;
  }
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  .icon {
  }
}

.redacted, .redactedInline {
  width: 100%;
  height: 100%;
  display: flex;
  .text {
    text-align: center;
  }
}

.redacted {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .lock {
    font-size: 32px;
  }
}

.redactedInline {
  flex-direction: row;
  .iconContainer {
    padding-top: 1px;
  }
  .lock {
    margin-top: 1px;
    margin-right: 5px;
    font-size: 12px;
  }
}

.redactedInline.compact {
  .iconContainer {
    padding-top: 0 !important;
  }
  .lock {
   margin-top: 0 !important;
 }
}

.descriptionContainer {
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 160px;
  :global(.ant-card-body) {
    padding: 12px;
  }
}

tr:not(:first-child) td .descriptionContainer {
  margin-top: 10px;
}
tr:not(:last-child) td .descriptionContainer {
  margin-bottom: 10px;
}