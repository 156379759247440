$resizer-width: 1px;
$resizer-border-width: 2px;

:global {
  .Resizer {
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .Resizer.vertical {
    cursor: col-resize;
    width: 2px;
  }

  .Resizer.horizontal {
    cursor: row-resize;
    height: 2px;
  }

  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
}
