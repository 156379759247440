
.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  :global(.react-grid-item.cssTransforms) {
    transition-property: none;
  }
  :global(.ant-picker-input) input, :global(.ant-checkbox-inner) {
    transition: none;
  }
  .scroll {
    width: 100%;
    overflow-y: auto;
  }
}

.gridItem {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  > .gridItemContent {
    position: initial;
    overflow: hidden;
    display: flex;
    flex: 1;
  }
  .title {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 5px;
    min-height: 32px;
    padding: 10px 10px;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    gap: 12px;
    .text {
      display: flex;
      flex: 1;
    }
    .movableIcon {
      font-size: 18px;
    }
    .icon {
      font-size: 16px;
      cursor: pointer;
      transition: color;
    }
  }
}

.gridItem.night {
  :global(.dc-chart) {
    g {
      text {
        fill: white !important;
      }
      :global(.axis) {
        path, line {
          stroke: gray;
        }
      }
    }
  }
}

.draggableHandle {
  display: flex;
  width: 100%;
  cursor: move;
}

.draggableHandle.full {
  position: absolute;
  width: auto;
  top: 0;
  right: 10px;
  bottom: 0;
  left: 0;
  z-index: 100000000000000000000;
  .buttonContainer {
    display: flex;
    position: absolute;
    top: 16px;
    right: 5px;
    width: 100%;
    justify-content: flex-end;
    pointer-events: none;
    .icon {
      pointer-events: auto;
      cursor: pointer;
      font-size: 16px;
      color: lightgray;
      transition: color;
    }
    .icon:hover {
      color: black;
    }
  }

}

.notAlternativeOne {
  padding-top: 60px !important;
}

.statisticCard {
  width: 100%;
  height: 120px;
  position: relative;
}
