.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 24px;
  gap: 24px;
  .dataPointSelectorTabs {
    width: 250px;
    .dataPointList {
      height: 100%;
    }
  }
  .textViewerTabs {
    width: 100%;
  }
  .extractedDataForm {
    width: 200px;
    margin-top: 20px;
  }
  :global(.ant-tabs-nav) {
    margin: 0 !important;
  }
  :global(.ant-tabs-content), :global(.ant-tabs-tabpane) {
    height: 100%;
  }
}
