.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  :global(.ant-menu-horizontal) {
    border-bottom: 0 !important;
  }
}

.menuContainer {
  display: flex;
  flex: 1;
}

.buttonContainer {
  display: flex;
  padding: 0 20px;
  align-items: center;
  button:not(:last-child) {
    margin-right: 8px;
  }
  .userNameContainer {
    margin-right: 16px;
    padding-top: 4px;
  }
}

.menuItem {
  display: flex;
  flex-direction: row;
}

.icon {
  font-size: 10px;
}

.checkContainer {
  width: 20px;
}

.loginFormContainer {
  width: 350px;
  min-height: 250px;
}

.loginSpinner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

.loginForm {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  padding: 30px 20px 0 20px;
  min-height: 250px;
}

.loginFormInput {
  width: 250px;
}

.loginFormForgot {
  float: right;
}

.loginFormButton {
  width: 200px;
}

.loginFormRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  width: 300px;
}

.loginMenuContainer:hover {
  background: rgb(253, 253, 253);
}
