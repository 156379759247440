.content {
    display: flex;
    flex-direction: row;
    .right, .left {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}

.map {
    width: 100%;
    height: 100%;
}
