.container {
  position: absolute;
  left: 0;
  top:0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  :global(.ant-tag) {
    transition: none;
    margin-bottom: 4px;
  }
}

.content {
  margin: 12px 10px 10px 10px;
}

.alternativeOne.content {
  margin-top: 65px !important;
}

.cellRenderer {
  padding: 10px;
//  height: 160px;
}

.lastRow {
  padding-bottom: 50px !important;
}

.grid {
  overflow: hidden !important;
}
