.tabBar {
  > :global(.ant-tabs-nav) {
    padding: 10px 20px 0 20px;
  }
  :global(.ant-tabs-tab:not(.ant-tabs-tab-active)) {
  }
  :global(.ant-tabs-content) {
    height: 100%;
  }
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  > .icon {
    display: inline-block;
    font-size: 12px;
  }
  gap: 6px;
}
